import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useState } from "react";
import { CageHamperRequest } from "../../models/CageHamperRequest";
import TextAreaMessageField from "../common/Form/TextAreaMessageField";
import Widget from "../common/Widget";
import { useStore } from "../../stores/store";
import InputQtyField from "../common/Form/InputQtyField";
import InputDateField from "../common/Form/InputDateField";
import { WidgetProps } from "../../models/WidgetProps";
import DisabledOverlay from "../common/DisabledOverlay";
import { MaxRequestMessage } from "../../constants/Messages";

const  CHRequestForm: React.FC<WidgetProps> = ({width,heading,menuItems,helpText}) => {
    const {hotelPortalStore:{chRequestSent, chRequestSuccess, sendCageHamperRequest}, userStore: {currentShop,user}, commonStore:{hamperCageRequestCount}} = useStore();
    const [chRequest, setChRequest] = useState<CageHamperRequest>({accountCode:'', cageHamper: '', cages:0, hampers:0, rejectBags:0, message:'', dateRequired: new Date() });
    const [submitted, setSubmitted] = useState(false);
    const message = () => (chRequestSuccess ? 'Request sent successfully' : 'An error occured while sending request');
    useEffect(() => {
        if(currentShop)
            setChRequest({...chRequest, accountCode: currentShop!.accountCode, cageHamper: currentShop!.cageHampers});
    }, [currentShop])
    
    const handleSubmit = (values: CageHamperRequest) =>{
        sendCageHamperRequest(values);
        setSubmitted(true);
    } 
    let widgetWidth: string;
    switch(width){
        case 1:
            widgetWidth='width-25';
            break;
        case 2:
            widgetWidth='width-50';
            break;
        case 3:
            widgetWidth='width-75';
            break;
        case 4:
            widgetWidth='width-100';
            break;
    }
    return(
        <>
        <Formik
                initialValues={chRequest}
                enableReinitialize={true}
                onSubmit={handleSubmit} 
            >{({handleSubmit, resetForm, isSubmitting, touched, dirty}) => (
                <Form className={`widget-form-wrapper ${widgetWidth}`} onSubmit={handleSubmit}>
                    <Widget 
                        width={4} 
                        noBorder={false} 
                        heading={heading} 
                        helpText={helpText}
                        hasFooter={true} 
                        showInfoBox={submitted && chRequestSent} 
                        message={message()} 
                        success={chRequestSuccess}
                        defaultDisabled={(!!user && hamperCageRequestCount >= user.maxRequestsAllowed) || (isSubmitting && dirty && !!touched)}
                        resetFunction={() =>{ resetForm(); setSubmitted(false);}}
                        >
                        {!!user && hamperCageRequestCount >= user.maxRequestsAllowed && (<DisabledOverlay message={MaxRequestMessage} showClose={false} />)}
                        <div className='widget-form'>
                            <div className="widget-form-group-row">
                            {(currentShop!.cageHampers === 'C' || currentShop!.cageHampers === 'CH') && (
                            <div className="widget-form-input-group">
                                <label className="input-label">Cages</label>
                                <InputQtyField name='cages' disabled={isSubmitting && dirty && !!touched}  />
                            </div>)}
                            {(currentShop!.cageHampers === 'H' || currentShop!.cageHampers === 'CH') && (
                            <div className="widget-form-input-group">
                                <label className="input-label">Hampers</label>
                                <InputQtyField name='hampers' disabled={isSubmitting && dirty && !!touched} />
                            </div>)}
                            <div className="widget-form-input-group">
                                <label className="input-label">Reject Bags</label>
                                <InputQtyField name='rejectBags' disabled={isSubmitting && dirty && !!touched} />
                            </div>
                            <div className="widget-form-input-group">
                                <label className="input-label">Date Required</label>
                                <InputDateField
                                    className="input-date-field" 
                                    name="requiredOn" 
                                    disabled={isSubmitting && dirty && !!touched}
                                    dateFormat="dd/MM/yyyy"
                                    showMonthDropdown
                                    showYearDropdown 
                                    dropdownMode="select"
                                    todayButton="Today"
                                />
                            </div>                            
                            </div>
                            <TextAreaMessageField name='message' fieldLabel='Message' placeholder='Your message...' fieldRows={12} disabled={isSubmitting && dirty && !!touched} />
                        </div>
                    </Widget>
                </Form>

            )}
                
            </Formik>
        </>
    )
}
export default observer(CHRequestForm);
