import { MD5 } from "crypto-js";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { ApiResponseStatus } from "../models/ApiResponse";
import { TechSupportMessage } from "../models/techSupportMessage";
import { ForgotPasswordValues, ResetPasswordInputValues, ResetPasswordValues, User, UserCredentials, UserShop } from "../models/User";
import { store } from "./store";
import { RequestType } from "../models/RequestTypeEnum";

export default class UserStore {
    user: User | null = null;
    currentShop: UserShop | undefined = this.restoreCurrentShop();
    showForgotPassword = false;
    requestSent = false;
    responseSuccess = false;
    supportMessageSent = false;
    supportMessageSuccess = false;
    showChangeAccount = false;
    prefilledUserEmail = "";
    constructor(){
        makeAutoObservable(this);
        reaction(
            () => this.currentShop,
            shop => {
                if(shop){
                    window.sessionStorage.setItem('ml_shop',JSON.stringify(shop));
                } else {
                    window.sessionStorage.removeItem('ml_shop');
                }
            }
        )
    }
    setShowForgotPassword = (value: boolean = true) =>{
        this.showForgotPassword = value;
    }
    setSupportMessageSent = (value:boolean = true) =>{
        this.supportMessageSent = value;
    }
    setSupportMessageSuccess = (value:boolean = true) =>{
        this.supportMessageSuccess = value;
    }
    setRequestSend = (value: boolean = true) => {
        this.requestSent = value;
    }
    setResponseSuccess = (value: boolean = true) =>{
        this.responseSuccess = value;
    }
    setShowChangeAccount = (change: boolean = true) => {
        this.showChangeAccount = change;
    } 
    setPrefilledUserEmail = (email: string) => {
        this.prefilledUserEmail = email;
    }
    private restoreCurrentShop(){
    const shop = window.sessionStorage.getItem('ml_shop');
    if(shop)
        return JSON.parse(shop);
    }
    get canChangeAccount(){
        if(this.user)
            return this.user.locations.length > 1 || (this.user.locations.length === 1 && this.user.locations[0].userShops.length > 1)
        else
            return false;
    }

    login = async (values: UserCredentials) => {
        try{
            const response = await agent.Account.authenticate(values);
            if(response && response.status === ApiResponseStatus.responseOK){
                store.commonStore.setToken(response.result.token);
                runInAction(() => {
                    this.user = response.result;
                })
                if(this.user){
                    if(this.user.locations){
                        if(this.user.locations.length > 0){
                            this.setCurrentShop(this.user.locations[0].userShops[0]);
                            Object.keys(RequestType).map((_val,index) => store.commonStore.getRequestCount(index))
                        }
                    }
                    store.commonStore.loadUserModules();
                    store.commonStore.loadManuals();
                    store.commonStore.loadContacts();
                }
            } else {
                if(response.status === ApiResponseStatus.responseError)
                    throw Error(response.error.description);
                else
                    throw Error("Unable to authenticate")
            }
        } catch(error){
            store.commonStore.addErrorLog("UserStore.login",error);
            throw(error);
        }
    }

    logOff = () => {
        runInAction(() =>{
            this.user = null;
            this.currentShop = undefined;
        })
        store.commonStore.setToken(null);
        store.commonStore.resetUserModules();
        store.commonStore.clearPreviousRoute();
        store.hotelPortalStore.resetAll();
        store.reportsStore.resetAll();
        window.sessionStorage.removeItem('ml_shop');
        window.sessionStorage.removeItem('ml_jwt');
    } 

    
    setCurrentShop = (shop: UserShop | undefined) => {
        runInAction(() => this.currentShop = shop)
        if(shop){
            agent.ActivityLogs.addLoginLog({
                                    locationUID: shop.locationUID,
                                    shopNo: shop.shopNo
                                })
        }
    }
    currentUser = async () => {
        try{
            const response = await agent.Account.currentUser();
            if(response && response.status === ApiResponseStatus.responseOK){
                store.commonStore.setToken(response.result.token);
                runInAction(() => {
                    this.user = response.result;
                })
                if(this.user){
                    if(this.user.locations){
                        if(this.user.locations.length > 0){
                            if(!this.currentShop)
                                this.setCurrentShop(this.user.locations[0].userShops[0]);
                            Object.keys(RequestType).map((_val,index) => store.commonStore.getRequestCount(index))
                        }
                    }
                    store.commonStore.loadUserModules();
                    store.commonStore.loadManuals();
                    store.commonStore.loadContacts();
                }
            }
        } catch(error){
            store.commonStore.addErrorLog("UserStore.currentUser",error);
            throw(error);
        }
    }

    get isLoggedIn() {
        return !!this.user;
    }
    forgotPassword = async (emailId: string) => {
        this.setRequestSend(false);
        this.setResponseSuccess(false);
        try{
            const forgot: ForgotPasswordValues = {
                emailId: emailId,
                laundryCode: store.commonStore.currentLaundry!.laundryCode,
                laundryUID: store.commonStore.currentLaundry!.uniqueRecId,
                webAppKey: store.commonStore.currentLaundry!.webAppKey,
                webAppPath: store.commonStore.currentLaundry!.webAppUrl
            }
            var response = await agent.Account.forgotPassword(forgot);
            if(response.status === ApiResponseStatus.responseOK)
                this.setResponseSuccess(response.result);
            else{
                    this.setResponseSuccess(false);
                    throw(response.error.description);
            }
        } catch (error){
            this.setResponseSuccess(false);
            store.commonStore.addErrorLog("UserStore.forgotPassword",error);
            throw error;
        } finally { 
            this.setRequestSend();
        }
    }
    resetPassword = async (values: ResetPasswordInputValues) => {
        this.setRequestSend(false);
        this.setResponseSuccess(false);
        try{
            const reset: ResetPasswordValues = {
                laundryCode: store.commonStore.currentLaundry!.laundryCode,
                webAppKey: store.commonStore.currentLaundry!.webAppKey,
                oldPasswordHash: '',
                passwordHash: MD5(values.password1).toString().toUpperCase(),
                resetKey: values.resetKey
            };
            var response = await agent.Account.resetPassword(reset);
            if(response.status === ApiResponseStatus.responseOK)
                this.setResponseSuccess(response.result);
            else{
                this.setResponseSuccess(false);
                throw(response.error.description);
            }
        } catch (error) {
            this.setResponseSuccess(false);
            store.commonStore.addErrorLog("UserStore.resetPassword",error);
            throw error;
        } finally {
            this.setRequestSend();
        }
    }
    changePassword = async (values: ResetPasswordInputValues) => {
        this.setRequestSend(false);
        this.setResponseSuccess(false);
        try{
            const reset: ResetPasswordValues = {
                laundryCode: '',
                webAppKey: '',
                oldPasswordHash: MD5(values.oldPassword).toString().toUpperCase(),
                passwordHash: MD5(values.password1).toString().toUpperCase(),
                resetKey: ''
            };
            var response = await agent.Account.changePassword(reset);
            if(response.status === ApiResponseStatus.responseOK)
                this.setResponseSuccess(response.result);
            else{
                this.setResponseSuccess(false);
                throw(response.error.description);
            }
        } catch (error) {
            this.setResponseSuccess(false);
            store.commonStore.addErrorLog("UserStore.changePassword",error);
            throw error;
        } finally {
            this.setRequestSend();
        }
    }
    getTechnicalSupport = async (message: TechSupportMessage) => {
        this.setSupportMessageSent(false);
        this.setSupportMessageSuccess(false);
        try{
            const response = await agent.Account.technicalSupport(message);
            runInAction(() => (this.setSupportMessageSuccess(response.status === ApiResponseStatus.responseOK)));
            if(response.status === ApiResponseStatus.responseOK)
                await store.commonStore.updateRequestCount(RequestType.RequestType_Technical_Support);

        } catch (error){
            store.commonStore.addErrorLog("UserStore.getTechnicalSupport",error);
            this.setSupportMessageSuccess(false);
        } finally {
            this.setSupportMessageSent();
        }
    }
}